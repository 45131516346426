/* Reset default styles for html and body */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Hide horizontal scrollbar if any */
}

.App {
  text-align: center;
  min-height: 100vh; /* Set the minimum height to make the footer stick to the bottom */
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: black;
  color: #fff;
  padding: 0; /* Remove padding */
}

.App-footer {
  background-color: black;
  font-size: large;
  color: white;
  align-items: center;
  text-align: center;
  padding: 0; /* Remove padding */
}

/* Responsive CSS */
@media (max-width: 768px) {
  .App-header {
    padding: 0; /* Remove padding */
  }

  .App-content {
    padding: 0; /* Remove padding */
  }

  .App-footer {
    padding: 0; /* Remove padding */
  }
}

/* Resetting default styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
}

/* Center content and limit width */
.App-content {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
}

.punane {
  font-size: x-large;
  font-weight: 600;
  color: red;
}
/* Heading styles */
h2 {
  color: #333333;
  margin-top: 30px;
  margin-bottom: 15px;
}

/* Paragraph styles */
p {
  color: #666666;
  line-height: 1.6;
}

/* TextDiv specific styles */
.textDiv,
.textDiv3 {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 6px;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
}

/* Sample work styles */
.naidis {
  font-weight: bold;
  color: #555555;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .App-content {
    padding: 15px;
  }

  .textDiv,
  .textDiv3 {
    padding: 15px;
  }
}

.border {
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px 8px #888888;
  margin-bottom: 30px;
}
